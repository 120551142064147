@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #D76425 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #D76425 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-menu-horizontal {
  border-bottom:#D76425;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #D76425;
  text-shadow: 0 0 0.25px currentColor;
}

.runeBtn {
  border-radius:10px;
  width: 115px;
  height:115px;
  border-color:#272d33;
  background:#272d33;
  text-align: center;
  padding: 8px;
  color: gray;
}

.runeBtn2 {
  border-radius:10px;
  width: 115px;
  height:115px;
  border-color:#272d33;
  background:#D76425;
  text-align: center;
  padding: 8px;
}

.runeBtn:hover {
  border-color:#324252;
  background:#324252;
  cursor: pointer;
}

.runeBtn2:hover {
  cursor: pointer;
}

.ant-card {
  border: solid 1px #324252;
}

.ant-card:hover {
  border: solid 1px #324252;
}

@component-background: #1E1E1E;@text-color: white;@primary-5: white;@primary-color: darkslategrey;@heading-color: #D76425;@layout-header-background: #1E1E1E;@menu-item-active-bg: darkslategrey;@menu-dark-item-active-bg: darkslategrey;@link-color: #D76425;@timeline-dot-bg: transparent;@table-bg: #1E1E1E;@table-header-bg: #1E1E1E;@table-header-color: #D76425;@table-row-hover-bg: darkslategrey;@pagination-item-bg: #1E1E1E;@pagination-item-bg-active: darkslategrey;@pagination-item-input-bg: #1E1E1E;@btn-link-hover-bg: #1E1E1E;@icon-color-hover: #1E1E1E;@icon-color: white;